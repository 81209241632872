import React, { useEffect, useState } from 'react'
import getAppointmentHistory from '../../doctorAppointmentManager/components/pendingAppointments/api/getAppointmentHistory';
import { Link } from 'react-router-dom';
import { handleImageError, mergeDateAndformatTime } from '../../common/helpers/helper';

const DoctorPendingList = () => {
    const [pendingAppointments, setPendingAppointments] = useState([])
    const [errorMessage, setErrorMessage] = useState(false);




    const pendingAppointmentHistory = async () => {
        const getHistory = await getAppointmentHistory(setErrorMessage);
        setPendingAppointments(getHistory?.pending)
        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };
    useEffect(() => {
        pendingAppointmentHistory()
    }, [])

    return (
        pendingAppointments?.length > 0 ?
            <div className='wrapper-box doctor-list mis-doctores-list pending' style={{ marginBottom: "50px" }}>
                <div className="mis-doctores-header">
                    <h2 className='common-title pb-5'>Mis próximas citas</h2>
                    <Link to="/appointment-manager-doctor" state={{activeTab : 2}}> Ver todo </Link>

                </div>
                <div className='grid grid-cols-4 gap-8'>

                    {pendingAppointments?.slice(0, 4)?.map((appointment, index) => (
                        <div className="doctor-card-2">

                            <div className="head">
                                <small> {appointment?.appDate}</small>
                                <small> {mergeDateAndformatTime(appointment?.appDate, appointment?.appTime, "hh:mm A")}</small>
                            </div>
                            <div className="doctor-card" key={index}>

                                <img
                                    src={process.env.REACT_APP_API_SERVER + appointment?.doctor_image}
                                    alt="Doctor Avatar"
                                    className="avatar"
                                    onError={handleImageError}
                                // onError={(e) => (e, API_BASE_URL + appointment?.doctor_image)}
                                />
                                <div className="info">
                                    <span className="specialty">Dr. {appointment?.doctorFullName}</span>
                                    <span className="specialty">{appointment?.speciality}</span>
                                    <span className="specialty">{appointment?.motiveName}</span>
                                </div>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
            : null
    )
}

export default DoctorPendingList