import FormFieldsContainer from '../../../common/components/containers/forms/FormFieldsContainer';
import WhiteCardContainer from '../../../common/components/containers/forms/WhiteCardContainer';
import ProfileFormHeader from '../ProfileFormHeader/ProfileFormHeader';
import useAppointmentHistory from './hooks/useAppointmentHistory';
import HalfRow from '../../../common/components/formElements/HalfRow';
import SimpleDataTable from './components/simpleDataTable/SimpleDataTable';
import { useEffect, useState } from 'react';
import HistoryAppointmentDetails from './components/appointmentDetails/HistoryAppointmentDetails';

const appointmentStateOptions = [
  { id: 1, value: 'finalizadas', label: 'Finalizadas' },
  { id: 2, value: 'pendientes', label: 'Pendientes' }
];

export default function AppointmentHistory({ page }) {
  const { completedTableInstance, isLoading, pendingTableInstance, _closeAppointmentView, selectedAppointment, selectionMode } = useAppointmentHistory();

  const [selectedTable, setSelectedTable] = useState('pendientes');

  useEffect(() => {
    if (page == "completed") {
      setSelectedTable("finalizadas")
    }
  }, [page])

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue ">
      {/*onSubmit={handleSubmit(saveEducation)}*/}
      <WhiteCardContainer>
        {JSON.stringify(selectedAppointment) === '{}' && (
          <FormFieldsContainer>
            <ProfileFormHeader title={'Ver Historial'} initialText={'Utiliza las opciones para filtrar los datos'} />
            <div className="grid grid-cols-6 gap-6">
              <HalfRow>
                <label htmlFor={'viewAppointments'} className="block text-sm font-medium text-gray-700">
                  Estado de Citas
                </label>
                <select
                  id={'viewAppointments'}
                  name={'viewAppointments'}
                  value={selectedTable}
                  onChange={(e) => setSelectedTable(e.target.value)}
                  className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm"
                >
                  {appointmentStateOptions &&
                    appointmentStateOptions.map((opt) => {
                      return (
                        <option key={opt.label} value={opt.value}>
                          {opt.label}
                        </option>
                      );
                    })}
                </select>
              </HalfRow>
            </div>
          </FormFieldsContainer>
        )}
        {!isLoading && completedTableInstance && selectedTable === 'finalizadas' && JSON.stringify(selectedAppointment) === '{}' && <SimpleDataTable tableInstance={completedTableInstance} />}

        {!isLoading && pendingTableInstance && selectedTable === 'pendientes' && JSON.stringify(selectedAppointment) === '{}' && <SimpleDataTable tableInstance={pendingTableInstance} />}

        {!isLoading && JSON.stringify(selectedAppointment) !== '{}' && <HistoryAppointmentDetails appointment={selectedAppointment} onCancel={_closeAppointmentView} selectionMode={selectionMode} />}
      </WhiteCardContainer>
    </div>
  );
}
