import { CardWrap, EducaciónIcon, MapIcon } from './DoctorResultsTabsElement';

const Perfil = ({ doctorData }) => {
  const latitude = doctorData.latitude;
  const longitude = doctorData.longitude;

  console.log("doctorData", doctorData)

  const getAddressAndRedirect = async (latitude, longitude, address) => {
    try {
      var response = '';
      var data;
      if (address) {
        response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyD7SKnOwSzDfNOeNByZsQocJBZ7nZgZHCE`);
        data = await response.json();
      }
      if (!address || data.results.length == 0) {
        response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD7SKnOwSzDfNOeNByZsQocJBZ7nZgZHCE`);
        data = await response.json();
      }
      if (response.ok) {
        if (data.results.length > 0) {
          const address = data.results[0].formatted_address;
          const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
          window.open(googleMapUrl, '_blank');
        } else {
          console.log('No results found.');
        }
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <>
      {/* clinic 1 */}
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center">
          <MapIcon />
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red">
            Clinica 1
          </h2>
          <span className="flex items-center text-sm mr-5 py-2 px-8 text-black docter-tag bg-sky-50 rounded-full ml-20 mt-0">San Salvador</span>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>{doctorData.address}</p>
          </div>
          <button className=" py-3 px-4 bg-sky-400 rounded-full text-white font-medium text-md" onClick={() => getAddressAndRedirect(latitude, longitude, doctorData.address)}>
            ¿Cómo llegar?
          </button>
        </dl>
      </CardWrap>

      {/* clinic 2 */}
      {/* <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center">
          <MapIcon />
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red">
            Clinica 2
          </h2>
          <span className="flex items-center text-sm mr-5 py-2 px-8 text-black docter-tag bg-sky-50 rounded-full ml-20 mt-0">Santa Tecla</span>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>Av. Manuel Enrique Araujo, número 624, San Salvador, San Salvador</p>
          </div>
          <button className=" py-3 px-4 bg-sky-400 rounded-full text-white font-medium text-md">¿Cómo llegar?</button>
        </dl>
      </CardWrap> */}

      {/* Education */}
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center">
          <EducaciónIcon />
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red">
            Educación
          </h2>
        </div>
        <dl className="px-6 py-6 chain-list-box">
          <div className="flex items-start chain-list px-6 py-4">
            <ul>
              {doctorData?.education?.map((item, i) => (
                <li key={i} className="text-base text-black pl-8 pb-10">
                  {item.educationName + ' ' + item.educationDetail}
                </li>
              ))}
            </ul>
          </div>
        </dl>
      </CardWrap>

      {/* Experiencia laboral */}
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  experiencial-box-head">
          <figure className="experiencial-icon">
            <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M29.6674 30H2.33263C1.04842 30 0 28.9405 0 27.6426V20.1958C0 19.6086 0.471579 19.132 1.05263 19.132C1.63368 19.132 2.10526 19.6043 2.10526 20.1958V27.6426C2.10526 27.7703 2.20632 27.8724 2.33263 27.8724H29.6632C29.7895 27.8724 29.8905 27.7703 29.8905 27.6426V19.9234C29.8905 19.3362 30.3621 18.8596 30.9432 18.8596C31.5242 18.8596 31.9958 19.3362 31.9958 19.9234V27.6383C32 28.9405 30.9516 30 29.6674 30Z"
                fill="black"
              />
              <path
                d="M16.1053 19.7872C9.76842 19.7872 3.71368 17.5063 1.40632 16.5276C0.551579 16.1659 0 15.3446 0 14.434V8.65102C0 7.39996 1.04421 6.38293 2.32842 6.38293H29.6716C30.9558 6.38293 32 7.39996 32 8.65102V14.4383C32 15.3404 31.4568 16.1574 30.6147 16.5234C28.3621 17.5063 22.4421 19.7872 16.1053 19.7872ZM2.32842 8.51059C2.19368 8.51059 2.10526 8.5957 2.10526 8.65102V14.4297C2.10526 14.4808 2.15158 14.5319 2.21895 14.5617C4.41263 15.4936 10.1516 17.6553 16.1053 17.6553C22.0505 17.6553 27.6505 15.4978 29.7811 14.5702C29.8484 14.5404 29.8947 14.4893 29.8947 14.4383V8.65102C29.8947 8.5957 29.8063 8.51059 29.6716 8.51059H2.32842Z"
                fill="black"
              />
              <path
                d="M10.5257 5.10638C10.0625 5.10638 9.68359 4.7234 9.68359 4.25532V2.40426C9.68359 2.16596 9.78465 1.93617 9.95728 1.77447L11.6415 0.221277C11.7973 0.080851 11.9994 0 12.2099 0H19.7636C19.9741 0 20.172 0.0765957 20.3278 0.217021L21.9615 1.70638C22.1383 1.86809 22.2394 2.09787 22.2394 2.34043V4.13191C22.2394 4.6 21.8604 4.98298 21.3973 4.98298C20.9341 4.98298 20.5552 4.6 20.5552 4.13191V2.71915L19.4394 1.70213H12.5341L11.3678 2.77872V4.25532C11.3678 4.7234 10.9889 5.10638 10.5257 5.10638Z"
                fill="black"
              />
            </svg>
          </figure>
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red education-title">
            Experiencia laboral
          </h2>
        </div>
        <dl className="px-6 py-6 chain-list-box">
          <div className="flex items-start chain-list px-6 py-4">
            <ul>
              {doctorData?.experience?.map((item, i) => (
                <li key={i} className="text-base text-black pl-8 pb-10">
                  {item.experienceName + ' ' + item.experienceDetail}
                </li>
              ))}
            </ul>
          </div>
        </dl>
      </CardWrap>
    </>
  );
};

export default Perfil;
